import 'bootstrap';
import Maska from 'maska';
import { createApp } from 'vue';
import '@/theme/style/style.scss';
import mqtt from 'mqtt/dist/mqtt';
import routes from '@/config/routes.ts';
import Toaster from "@meforma/vue-toaster";
import config from '@/config/app.config.ts';
import VueSplide from '@splidejs/vue-splide';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from '@/theme/layouts/AppLayout.vue';
import store from '@/shared/store/index.ts'; // vuex store
import Heading from '@/theme/components/HeadingComponent.vue'
import Loader from '@/theme/components/LoaderComponent.vue'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

const mqttClient = mqtt.connect(config.MQTT_URL, config.MQTT_CONFIG);

const app = createApp(App)
    .use(routes)
    .use(VueSplide)
    .use(Toaster)
    .use(Maska)
    .use(VueSidebarMenu)
    .provide('mqtt', mqttClient)
    .use(store())
    .component('page-heading', Heading)
    .component('page-loader', Loader)
    .mount('#app');

global.app = app;
